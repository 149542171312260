<template>
  <v-container fluid>
    <v-row align="center"
        justify="center">
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-card class="elevation-12">
            <v-card-title class="secondary white--text justify-center">
              <img :src="logoPath" alt="Legna Software Logo" height="35" class="ml-2"/>
              </v-card-title>
            <v-card-text class="pa-6">
              <div style="width: 100%; text-align: center;">
                <img
                :src="microsoftSignInPath"
                alt="Log in with Microsoft"
                style="cursor: pointer;"
                data-testid="log-in-with-microsoft"
                @click="signIn">
              </div>
            </v-card-text>
          </v-card>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { signInWithRedirect } from '@/msal-browser/Authentication.js'

export default {
  name: 'Login',

  data: () => ({
    logoPath: './img/Legna-Secondary-WHITE.svg',
    microsoftSignInPath: './img/ms-symbollockup_signin_light.svg'
  }),

  computed: {
    ...mapGetters('user', ['loginWithRedirectResult'])
  },

  watch: {
    loginWithRedirectResult (val) {
      if (val) {
        this.$emit('open-multi-tenant-dialog')
      }
    }
  },

  methods: {
    signIn () {
      signInWithRedirect()
    }
  }
}
</script>
